import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate, Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";

const CheckOut = () => {
  const { cartItems } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [cvc, setCvc] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country/Region is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!address) newErrors.address = "Street address is required";
    if (!city) newErrors.city = "Town / City is required";
    if (!cardNumber) newErrors.cardNumber = "Card Number is required";
    if (!expiryDate) newErrors.expiryDate = "Expiry Date is required";
    if (!cvc) newErrors.cvc = "Card Code (CVC) is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert("Order placed!");
      navigate("/paysuccess");
    }
  };

  const isFormValid = () => {
    return (
      email && country && phone && firstName && lastName && city && address
    );
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  return (
    <>
      <Header />
      <div className="main-containerCheck">
        <div className="cart-header">
          <FontAwesomeIcon icon={faList} style={{ color: "#1e85be" }} />
          <p>
            Some items in your basket are discounted for members. Log in to
            claim them!
          </p>
        </div>
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <h3>Billing details</h3>
              <form>
                <div className="firstLast">
                  <div className="form-group1 form-group-half1">
                    <label htmlFor="first-name">First name *</label>
                    <input
                      type="text"
                      id="first-name"
                      name="first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="form-group1 form-group-half">
                    <label htmlFor="last-name">Last name *</label>
                    <input
                      type="text"
                      id="last-name"
                      name="last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="company-name">Company name (optional)</label>
                  <input type="text" id="company-name" name="company-name" />
                </div>

                <div className="form-group form-group-half">
                  <label htmlFor="country">Country/Region *</label>
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => {
                      setSelected(code);
                      setCountry(code);
                    }}
                    className="check-select"
                  />
                  {errors.country && <p className="error">{errors.country}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="address">Street address *</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="House number and street name"
                  />
                  {errors.address && <p className="error">{errors.address}</p>}

                  <input
                    type="text"
                    id="address2"
                    name="address2"
                    value={address}
                    className="twoAdd"
                    required
                    placeholder="Apartment, suite, unit, etc. (optional)"
                  />
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="city">Town / City *</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                  {errors.city && <p className="error">{errors.city}</p>}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="company-name">
                    State / County (optional)
                  </label>
                  <input type="text" id="company-name" name="company-name" />
                </div>

                <div className="form-group form-group-half">
                  <label htmlFor="phone">Phone*</label>
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </form>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              {cartItems.length > 0 && (
                <div className="cart-summary1">
                  <div className="rightSum22">
                    <h2 className="basketDiv22">Your order</h2>

                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh22">Subtotal</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalTh1">Shipping</th>
                          <th className="subtotalTxt1">
                            Enter your address to view shipping options.
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalTh">Total</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(2)}
                          </th>
                        </tr>

                        <p className="subtotalTh2">Have a gift card?</p>
                        <div className="gift-input22">
                          <input type="text" placeholder="Enter your code…" />
                          <button>APPLY</button>
                        </div>
                      </tbody>
                    </table>
                    <div className="rightBotDiv">
                      <div className="cartDebit">
                        <button>Credit Card</button>
                      </div>
                      <div className="cartInputDiv">
                        <div className="cardNumber">
                          <label>Card Number *</label>
                          <input
                            type="text"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                            placeholder="1234 1234 1234 1234"
                            required
                          />
                          {errors.cardNumber && (
                            <p className="error">{errors.cardNumber}</p>
                          )}
                        </div>
                        <div className="cartData">
                          <div
                            className="expiryData"
                            style={{ marginRight: "4%" }}
                          >
                            <label>Expiry Date *</label>
                            <DatePicker
                              selected={expiryDate}
                              onChange={(date) => setExpiryDate(date)}
                              dateFormat="MM/yy"
                              showMonthYearPicker
                              placeholderText="MM/YY"
                              required
                            />
                            {errors.expiryDate && (
                              <p className="error">{errors.expiryDate}</p>
                            )}
                          </div>
                          <div className="cartCode">
                            <label>Card Code (CVC) *</label>
                            <input
                              type="text"
                              value={cvc}
                              onChange={(e) => setCvc(e.target.value)}
                              placeholder="CVC"
                              required
                            />
                            {errors.cvc && (
                              <p className="error">{errors.cvc}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="privacy-bot">
                        Your personal data will be used to process your order,
                        support your experience throughout this website, and for
                        other purposes described in our
                        <Link to="privacy">privacy policy.</Link>
                      </p>
                      <button
                        className="check-checkout-button"
                        onClick={handlePlaceOrder}
                      >
                        Pay Order
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CheckOut;
