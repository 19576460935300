import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function AboutUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="helpDiv">
        <div className="contact-top">
          <div className="col-12">
            <div className="mt">
              <div className="mt-2"></div>
            </div>
            <h1> About Fen Farm Dairy </h1>
          </div>
        </div>

        <div className="refundMid">
          <div className="helpTxt">
            <h1>Our Story</h1>
            <h3>
              We had always been just a normal dairy farm, selling normal milk
              to normal British supermarkets.
            </h3>
            <p>
              Then, on a rainy August day in 2011, we met a hen farmer who
              changed our lives. It was nothing extraordinary. He was just
              selling his eggs by his gate, as you do. A rusty old honesty tin
              with a stone on top. But sometimes it’s the unremarkable things
              that get you thinking. Why don’t dairy farmers sell their milk
              this way?
            </p>
            <p>
              Within a month, our cow-print shed was installed and our first 30
              bottles of raw cow’s milk went on sale. A jam jar honesty box with
              a stone on top. Those 30 bottles sold out within the hour, the
              final customer guzzling the whole bottle on the spot and
              requesting a refill. We learnt that people came back to the shed
              because of the high quality of our milk and the experience of
              having a personal connection to the farm.
            </p>
            <p>
              As sales grew, the honesty box sadly became a dishonesty box. A
              chance tweet from a friend in Italy introduced us to the concept
              of milk vending and within weeks we had installed the UK’s first
              raw milk vending machine. This had a transformative effect on the
              success of our farm. Seeing our customers enjoying our product had
              become a true enjoyment for us. It got us thinking once again…
            </p>
            <p>
              How could we make more of our milk into products that we could
              sell directly to people who would really and truly enjoy them?
            </p>
            <p>
              In 2012, we embarked on a research tour of the UK and Europe. We
              learned from some of the world’s finest cheesemakers and purchased
              by hand 72 beautiful Montbéliarde milking cows from the Jura
              region of France. Their milk was highly prized for cheesemaking on
              the continent.
            </p>
            <p>
              A year later our first cheese, Baron Bigod, went on sale and has
              been followed over the years by our raw butter, skyr yoghurt and
              many more products. Our products can now be found in the finest
              delis, restaurants and cheese counters in the UK and around the
              world.
            </p>
          </div>
          <div className="helpImg">
            <img alt="" src="/foot/102.webp" />
          </div>
        </div>
        <div className="refundMid">
          <div className="helpTxt2">
            <h1>Fen Farm Dairy</h1>
            <h3>
              We are an innovative 3rd generation family farm, aiming to set the
              standard for dairy sustainability.
            </h3>
            <p>
              In a few short years, Fen Farm products have taken the UK food
              scene by storm. Our Baron Bigod cheese has become a beloved menu
              staple of top chefs and is known to frequently grace the tables of
              Royalty. Our happy herd of Montbéliarde cows graze the fertile
              marshland of the Waveney River Valley and we are making their
              nourishing milk into the finest dairy products. We are excited to
              introduce you to our wares!
            </p>
            <h3>Our ethos</h3>
            <p>
              Here at Fen Farm, we believe in the importance of connecting
              people to the food they eat and the farm from which it came. We
              also believe it is our responsibility to be excellent stewards of
              the land we farm and the delicate ecosystems that live here. Our
              mission is to become carbon negative. We produce more energy than
              we use from renewable sources on the farm, including our unique
              cow-poo powered heat exchange system, biomass boiler and solar
              battery storage.
            </p>
            <h3>Jonny and Dulcie Crickmore</h3>
            <p>
              Jonny is the third generation of the Crickmore family to farm the
              land here at Fen Farm. He has been working on the farm since the
              age of four, when he would sneak out of bed at 3am and follow his
              Dad to the cowshed to help with the morning jobs. Now there is a
              little less sneaking involved but no fewer early mornings and wild
              enthusiasm. In Jonny’s spare time he’s both chairman of the Raw
              Milk Producers Association and the Specialist Cheesemakers
              Association, as well as sitting on the board of the Aldeburgh Food
              & Drink Festival.
            </p>
            <p>
              Dulcie left behind her day job as a costume designer, to take part
              in the marketing and business development at Fen Farm Dairy.
            </p>
            <p>
              Together, Jonny and Dulcie have diversified the original farm with
              the addition of a pioneering cheese, butter and yoghurt making
              business. Their focus is on making exceptional artisan products
              and proving that dairy farming can be part of the solution, not
              the problem.
            </p>
            <h3>Graham and Frances Crickmore</h3>
            <p>
              Graham is the second generation of the Crickmore family here on
              the farm. He has been working on Fen Farm since the age of 8, when
              it was owned by his uncle, Richard Cook and older sister, Joy.
              Although Richard and Joy are sadly no longer with us, their hard
              work and legacy lives on and Graham has dedicated his working life
              to building a thriving dairy farm. In recent years, Jonny and
              Graham have together taken the farm operations from strength to
              strength.
            </p>
            <p>
              Frances is a wildlife photographer, documenting the intricate
              ecosystems and ever-changing seasons of the Waveney River Valley.
              Frances and Graham met in 1969 at the tender age of 18, when he
              chased her home on her scooter to ask her for a date.
            </p>
            <h3>The Farm team</h3>
            <p>
              These are the amazing people who get out of their warm beds in the
              dark and sleet in February to tend to a newborn calf. The ones who
              still do the same dedicated job, whatever the weather, because
              with livestock, you can’t give it half measures.
            </p>

            <h3>The cheese and dairy team</h3>
            <p>
              These are our curd nerds. They navigate the labyrinth of complex
              dairy science on a daily basis, over a cuppa, like it was just
              your average morning chat. These guys are a coagulation of
              geniuses. They are the reason our products are the very best you
              will find anywhere.
            </p>

            <h3>The people who keep us in order</h3>
            <p>
              When you call us up and get a really flipping nice and organised
              ninja person on the end of the phone, you know you have reached
              the FFD office team. Without them to slap us into shape, we would
              be lost.
            </p>
          </div>
        </div>
        <div className="py-5">
          <div className="g-4">
            <div className="col-md-6">
              <h3 className="fw-bold">Recipes</h3>
              <div className="fs-5">Browse all our recipes here</div>
            </div>
            <div className="col-md-6">
              <img alt="" src="/foot/103.svg" />
              <h4>
                Baron Bigod Fritters with Apple and Fig Chutney by Fox & Goose
                chef Paul Yaxley
              </h4>
              <p>
                The Fox & Goose in Fressingfield has been consistently serving
                up some of the finest food in our region for as long as we can
                remember. We asked owner and head chef Paul Yaxley to share with
                us his favourite.
              </p>
            </div>
            <div className="col-md-6">
              <img alt="" src="/foot/104.svg" />
              <h4>
                Baron Bigod, Curried Apple & Date Chutney Rarebit by Fork Deli’s
                Justin Kett
              </h4>
              <p>
                Award-winning Suffolk chef Justin Kett opened Fork Deli in
                Hadleigh just over 2 years ago. We are proud to stock some of
                Fork’s delicious chutneys and sauces in our all-new milk shed
                here at the farm.
              </p>
            </div>
            <div className="col-md-6">
              <img alt="" src="/foot/105.svg" />
              <h4>Crumpet and Baron Bigod Pancetta Parcels by Crumpetorium</h4>
              <p>
                This is a really quick and easy recipe from Norfolk’s artisan
                crumpetiers, Crumpetorium. It’s perfect for a tasty lunch, or
                could be served up as part of a dinner meal by adding in your
                favourite side.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
