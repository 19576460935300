import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddToCart = () => {
    addToCart(product.id, quantities);
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleQuantityChange = (delta) => {
    setQuantities((prevQuantity) => {
      const newQuantity = Math.max(1, prevQuantity + delta);
      return newQuantity;
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
            <div className="goBackToShop">
              <Link to="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</Link>
            </div>
          </div>
          <div className="product-info">
            <div className="g-0">
              <div className="bg-quaternary">
                <h3>One Time Purchase</h3>
                <bdi>{product.price}</bdi>
              </div>
              <div className="inactive">
                <h3>Subscribe & Save</h3>
                <h4>Save 20%</h4>
              </div>
            </div>
            <div className="summary-container">
              <h1>{product.main}</h1>
              <div className="woocommerce-product-details__short-description">
                <p>{product.description}</p>
              </div>
              <div className="priceDiv">
                <span className="now-price">{product.price}</span>
              </div>
              <div className="member-pricing">
                Subscribe & Save Prices from:
                <span className="woocommerce-Price-amount">
                  {product.preprice}
                </span>
              </div>
              <div className="template_content">
                <div className="takeQuantity">
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(-1)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <input
                    type="number"
                    value={quantities}
                    min="1"
                    onChange={(e) =>
                      setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                    }
                    className="quantity-input"
                  />
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(1)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              <div className="btnDiv">
                <button className="add-to-cart" onClick={handleAddToCart}>
                  Add to Basket
                </button>
              </div>
              <div className="botFee">
                <div className="glsr-summary-rating">
                  <img alt="" src="/bungayImg/delivery.svg" />
                </div>
                <div className="deliveryDes">
                  For The Vast Majority Of UK Postcodes, Delivery Is £4.99, Or
                  Free For Orders Over £45.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
