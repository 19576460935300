import "../css/header.css";
import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const navigate = useNavigate();
  const handleAddToCart = () => {
    navigate("/cartpage");
  };
  const { cartItems, getProductById } = useContext(CartContext);

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };
  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftImg">
            <img alt="" src="/bungayImg/head1.jpg" />
          </div>
          <div className="rightT" onClick={handleAddToCart}>
            <p>£{calculateTotal().toFixed(2)}</p>
            <img alt="" src="/bungayImg/basket.svg" />
          </div>
        </div>
        <div className="headerMid">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA">
                  SHOP
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/aboutUs">About </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact">Contact </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="firstDescription22">
          <div className="mainTopThree">
            <div className="glsr-summary-rating">
              <img alt="" src="/bungayImg/delivery.svg" />
            </div>
            <div className="glsr-summary-stars22">
              <div className="main_reviews_based_on">
                Free Delivery On Orders Over £45
              </div>
            </div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-summary-rating">
              <img alt="" src="/bungayImg/become-a-friend.svg" />
            </div>
            <div className="glsr-summary-stars22">
              <div className="main_reviews_based_on">
                Join our Dairy Club And Get A 20% Discount
              </div>
            </div>
          </div>
          <div className="mainTopThree">
            <div className="glsr-summary-stars22">
              <div className="main_reviews_based_on">
                <img alt="" src="/bungayImg/fee.webp" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
