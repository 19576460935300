import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";
import "../css/main.css";
import productData from "../Datas/productData";

function Main() {
  const navigate = useNavigate();

  const toDetail = (id) => {
    navigate(`/shopDetails/${id}`);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <div className="text-container">
            <h1>Fen Farm Dairy - Shop</h1>
            <div className="ff-libre">
              Buy the finest dairy products in the country
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <div className="product-grid">
              {productData
                .filter((brand) => brand.id >= 1 && brand.id <= 8)
                .map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <Link to={`/shopDetails/${brand.id}`}>
                      <div className="image-container">
                        <img alt="" src={brand.images[0]} />
                        <h2 className="centered-text">{brand.name}</h2>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
          {/* mid */}
          <div className="container-mid">
            <div className="cheese-club-banner">
              <div className="cheese-left">
                <div className="cheese-left-txt">
                  <span className="ff-bebas">20% MEMBERS DISCOUNT</span>
                  <div className="join-cheese-club">FEN FARM DAIRY CLUB</div>
                  <div className="join-cheese-club-description">
                    Get your dairy essentials delivered to your door.
                  </div>
                </div>
              </div>
              <div className="cheese-right">
                <img alt="" src="/bungayImg/milk1.webp" />
              </div>
            </div>
          </div>
          {/* bot */}
          <div className="container-bot">
            <div className="product-carousel-container">
              <div className="pt-2">
                <h3>Bestselling Products This Month</h3>
              </div>
              <div className="data-swiper">
                <div className="swiper-all">
                  {productData
                    .filter((brand) => brand.id >= 9 && brand.id <= 11)
                    .map((brand) => (
                      <div className="swiper-slide">
                        <img
                          alt=""
                          src={brand.images[0]}
                          className="main-img"
                        />
                        <div className="shop-div">
                          <img
                            alt=""
                            src="/bungayImg/fee2.png"
                            className="fee-img"
                          />
                          <h2>{brand.name}</h2>
                          <h4>{brand.price}</h4>
                          <button onClick={() => toDetail(brand.id)}>
                            SHOP NOW
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
