import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="tact-head">
        <div className="mt">
          <div className="mt-2"></div>
        </div>
        <h1>Contact</h1>
        <button className="directBtn"> Get Directions</button>
        <div className="h4-box">
          <h4>Fen Farm Dairy, Flixton Road, Bungay NR35 1PD, UK</h4>
        </div>
      </div>
      <hr className="border-opacity-50" />
      <div className="contactAll">
        {/* left */}
        <div className="contactLeft">
          {/* left-top */}
          <div className="contactLeftTop">
            <div className="contactLeft1Title">
              <h2>CONTACT US</h2>

              <soan>
                Please send us a message by filling out the form below and we
                will get back with you shortly.
              </soan>
            </div>
          </div>
          {/* left-mid */}
          <div className="contactLeftMid">
            <div className="contactRightForm">
              <lable>Name:</lable>
              <br />
              <input type="text" placeholder="Enter Your Name" />
              <br />
              <lable>Surname:</lable>
              <br />
              <input type="text" placeholder="Enter Your Name" />
              <br />
              <lable>Phone Number:</lable>
              <br />
              <input type="text" placeholder="Enter Your Name" />
              <br />
              <lable>Email:</lable>
              <br />
              <input type="text" placeholder="Enter Your Email" />
              <br />
              <lable>Message:</lable>
              <br />
              <textarea placeholder="Enter Your Message" />
              <br />
              <div className="sub-div">
                <button className="subBtn">SUBMIT</button>
              </div>
            </div>
          </div>
        </div>
        {/* right */}
        <div className="contactRight">
          <div className="right-all-box">
            <div className="contactRightTxt">
              <h2>Customer Enquiries</h2>
              <p>
                We always love to hear from our customers, if you have any
                questions please let us know using any of the following:
              </p>
              <p>Farm Office – Opening times: Mon-Fri 9am-5pm.</p>
              <p>
                Email:<Link to="#">art@meniard.life</Link>
              </p>
              <p>Phone number: 01986 892 350</p>
            </div>
            <div className="contactRightTxt">
              <h2>Wholesale Enquiries</h2>
              <p>
                If you are interested in stocking Fen Farm Dairy products, we
                would love to hear from you. Please write to us at:
              </p>
              <p>
                Email:<Link to="#">art@meniard.life</Link>
              </p>
            </div>
            <div className="tact-svg">
              <h2>We’re Social:</h2>
              <div>
                <Link to="#">
                  <img alt="" src="/bungayImg/pic.svg" />
                </Link>
                <Link to="#">
                  <img alt="" src="/bungayImg/face.svg" />
                </Link>
                <Link to="#">
                  <img alt="" src="/bungayImg/twitter.svg" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
