const productData = [
  {
    id: 1,
    name: "Baron Bigod ",
    main: "Baron Bigod 3kg",
    description:
      "Our traditional farmhouse Brie style cheese (approximately 3kg) , handmade at Fen Farm from our own grass-fed Montbéliarde cows milk.",
    price: "£99.90",
    preprice: "£79.20",
    images: ["/bungayImg/101.webp", "/detail/101.webp"],
  },
  {
    id: 2,
    name: "Bungay Butter",
    main: "Bungay Butter Box Salted 1kg",
    description:
      "Our Bungay Raw Cultured Butter, from our own happy herd of grass-fed Montbeliarde and Friesian cows.",
    price: "£24.00",
    preprice: "£19.20",
    images: ["/bungayImg/103.webp", "/detail/102.jpg"],
  },
  {
    id: 3,
    name: "Guest Cheese",
    main: "St Helena 150g wedge",
    description:
      "St. Helena is a semi-soft raw milk cheese made by Blake Bowden and Julie Cheyney. Julie and Blake are based here at Fen Farm Dairy in Suffolk, where they use the warm unpasteurised milk from our free ranging Montbeliarde cows to make their formidably good cheeses.",
    price: "£7.00",
    preprice: "£5.60",
    images: ["/bungayImg/104.webp", "/detail/104.webp"],
  },
  {
    id: 4,
    name: "Gift Card",
    main: "Gift Card",
    description:
      "Gift cards are digital products only and will arrive via email.",
    price: "£20.00",
    preprice: "£16.00",

    images: ["/bungayImg/105.webp", "/detail/104.webp"],
  },
  {
    id: 5,
    name: "Raw Milk",
    main: "Raw Milk x 1L",
    description:
      "Our raw (unpasteurised), whole live cows milk. Straight from our free-ranging Montbéliarde herd and completely unprocessed.",
    price: "£3.30",
    preprice: "£2.64",
    images: ["/bungayImg/106.png", "/detail/106.webp"],
  },
  {
    id: 6,
    name: "Yoghurts",
    main: "Yoghurt Blackcurrant 400g",
    description:
      "A 400g pot of our handmade farmhouse yoghurt, with a thick layer of delicious England Preserves blackcurrant compote.",
    price: "£3.80",
    preprice: "£3.04",
    images: ["/bungayImg/107.jpg", "/detail/105.webp"],
  },
  {
    id: 7,
    name: "Cream",
    main: "Medlar Jelly",
    description:
      "HANDMADE IN NORFOLK BY EAST GATE LARDER A perfectly rich and sweet Medlar Jelly, handmade in Norfolk by East Gate Larder. Ideal with cheese as an alternative to quince jelly",
    price: "£4.75",
    preprice: " £3.80",
    images: ["/bungayImg/108.png", "/detail/108.jpg"],
  },
  {
    id: 8,
    name: "Accompaniments",
    main: "Wholemeal Buttermilk Crackers",
    description:
      "MADE WITH FEN FARM DAIRY BUTTERMILK Savoury crackers hand-baked in the Scottish Highlands by Mellis Cheese, using Fen Farm Dairy raw cultured buttermilk. Pairs well with any of our cheeses.",
    price: "£3.50",
    preprice: " £2.80",
    images: ["/bungayImg/109.jpg", "/detail/107.webp"],
  },
  {
    id: 9,
    name: "Baron Bigod Baby 250g",
    main: "Baron Bigod Baby 250g",
    description:
      "A baby sized version of our farmhouse Brie style cheese (approximately 250g), handmade at Fen Farm from our own grass-fed Montbeliarde cows milk. Your cheese will be hand wrapped in wax paper and packed in its own beautiful wooden box.",
    price: "£10.00",
    preprice: "£8.00",
    images: ["/detail/11.webp", "/detail/11.webp"],
  },
  {
    id: 10,
    name: "Bungay Butter Sheet Salted 1kg",
    main: "Bungay Butter Sheet Salted 1kg",
    description:
      "Our Bungay Raw Cultured Butter, from our own happy herd of grass-fed Montbeliarde and Friesian cows.",
    price: "£24.00",
    preprice: "£19.20",
    images: ["/detail/22.webp", "/detail/22.webp"],
  },
  {
    id: 11,
    name: "Bungay Butter Box Salted 200g",
    main: "Bungay Butter Box Salted 200g",
    description:
      "Our Bungay Raw Cultured Butter, from our own happy herd of grass-fed Montbeliarde and Friesian cows.",
    price: "£7.60",
    preprice: "£6.08",
    images: ["/detail/33.webp", "/detail/33.webp"],
  },
];

export default productData;
